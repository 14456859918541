.login {
  position: fixed;
  width: 90%;
  height: 6.666667rem;
  left: 50%;
  top: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  background-color: #c6e4ff;
  border-radius: 0.333333rem;
  overflow: hidden;
}

.login .title {
  background-color: #6da7ff;
  height: 0.973333rem;
  line-height: 0.973333rem;
  text-align: center;
  color: #fff;
  font-size: 0.4rem;
}

.container .item {
  width: 80%;
  background-color: rgba(246,251,255,.8);
  height: 0.973333rem;
  line-height: 0.973333rem;
  padding: 0 0.44rem;
  display: flex;
  align-items: center;
  margin: 0.32rem auto;
  border-radius: 0.2rem;
}

.container .item i.account_icon {
  display: block;
  width: 0.48rem;
  height: 0.613333rem;
  background-image: url("../../static/image/user_1.png");
  background-size: 0.48rem 0.613333rem;
}

.container .item i.pass_icon {
  display: block;
  width: 0.48rem;
  height: 0.613333rem;
  background-image: url("../../static/image/password.png");
  background-size: 0.48rem 0.613333rem;
}

.container .item .input {
  height: 0.973333rem;
  line-height: 0.973333rem;
  margin-left: 0.266667rem;
  border: none;
  background-color: transparent;
  outline: none;
}

.container .submit {
  background-image: linear-gradient(to right, #4BB5FF, #4596F3, #4BB5FF);
  height: 0.866667rem;
  width: 3.146667rem;
  color: #fff;
  line-height: 0.866667rem;
  text-align: center;
  margin: 0.4rem auto;
  border-radius: 0.2rem;
}

.radius {
  position: absolute;
  width: 10rem;
  height: 4rem;
  border-radius: 50%;
  bottom: -6.666667rem;
  bottom: -44%;
  background: #fff;
  left: -5%;
}

.mark {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
}

.other {
  width: 5.386667rem;
  height: 0.96rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  margin: 0 auto;
}

.other .item {
  width: 0.96rem;
  height: 0.866667rem;
  background-size: 0.96rem 0.866667rem;
  display: block;
}

.other .item.b1 {
  background-image: url("../../static/image/b1.png");
}

.other .item.b2 {
  background-image: url("../../static/image/b2.png");
}

.other .item.b3 {
  background-image: url("../../static/image/b3.png");
}