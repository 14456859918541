.mark {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
}

.con {
  width: 6.026667rem;
  height: 6.266667rem;
  background-image: url("../../static/image/bg.png");
  background-size: contain;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.con .bi {
  width: 2.813333rem;
  height: 1.04rem;
  margin: 0.133333rem auto;
  display: block;
}

.con .list .item {
  width: 90%;
  margin: 0 auto;
  height: 0.653333rem;
  margin-bottom: 0.12rem;
  background-color: #c6e4ff;
  text-align: center;
  line-height: 0.653333rem;
  color: #4388ed;
  border-radius: 0.266667rem;
}

.con .list .item:hover {
  color: #333;
  background-color: #fff;
  width: 95%;
}

.con .btn {
  width: 2.72rem;
  height: 0.733333rem;
  color: #333;
  line-height: 0.733333rem;
  text-align: center;
  background-color: #fff;
  border-radius: 0.266667rem;
  margin: 0 auto;
}