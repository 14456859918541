.footer {
  height: 0.933333rem;
  background: #3b3b3b;
  margin-top: 0.426667rem;
  font-size: 0.186667rem;
}

.footer .footer-container {
  width: 90%;
  line-height: 0.933333rem;
  color: #fff;
  margin: 0 auto;
  text-align: center;
}

.footer .footer-container a {
  color: #fff;
}

.footer .footer-container span {
  margin: 0 0.053333rem;
}